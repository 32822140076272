/************ Start navbar style ****************/
.navbar {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 5rem;
}

.nav-item {
  position: relative;
  display: inline-block;
  color: #231F20;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 4px;
}

.nav-item::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #0976BC;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.nav-item:hover::before,
.nav-item.active::before {
  width: 100%;
  left: 0;
}

.nav-item:hover,
.nav-item.active {
  color: #0976BC;
  text-decoration: none; 
}

/************ End navbar style ****************/
