.ck-content table tr:first-child {
    background-color: #0074d9; /* Blue header for the first row */
    color: white; /* White text color */
  }
  
  .ck-content table tr:first-child td,
  .ck-content table tr:first-child th {
    padding: 10px;
    border: 1px solid #d3d3d3;
  }
  